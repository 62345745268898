import React, { useEffect, useState } from "react";
import LogoHorizontal from "../../../../Assets/images/logoHorizontal.png";

// import fakeQrCode from "../../../../Assets/images/fakeQrCode.png";

import {
	Text,
	View,
	StyleSheet,
	Document,
	Page,
	Image,
	Font,
} from "@react-pdf/renderer";

import Roboto from "../../../../Assets/fonts/Roboto-Bold.ttf";
// import { dataRomaneio } from "../../dataRomaneio";
import { BusinessService } from "../../../../service/business_service";
import { PropertyService } from "../../../../service/property_service";
import { UserService } from "../../../../service/user_service";
import { OrderService } from "../../../../service/order_service";

Font.register({
	family: "Roboto bold",
	format: "truetype",
	src: Roboto,
});

const styles = StyleSheet.create({
	page: {
		paddingBottom: "75px",
		paddingTop: "50px",
		paddingLeft: "30px",
		paddingRight: "30px",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	logo: {
		width: "100px",
		height: "100px",
		marginRight: "-20px",
	},
	textTitleDocument: {
		color: "#03989E",
		textTransform: "uppercase",
		fontSize: "24px",
		fontFamily: "Roboto bold",
	},
	line: {
		height: "3px",
		backgroundColor: "#E0DFDF",
	},
	containerSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: "10px",
	},
	containerTotal: {
		alignItems: "flex-end",
	},
	textBold: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
		textTransform: "uppercase",
	},

	textBoldBig: {
		fontSize: "16px",
		marginBottom: "3px",
	},
	textNormal: {
		fontSize: "12px",
		textTransform: "uppercase",
	},
	body: {
		marginBottom: "30px",
	},
	textBoldFazenda: {
		fontSize: "18px",
		fontFamily: "Roboto bold",
	},
	textBoldPropriedade: {
		fontSize: "12px",
		fontFamily: "Roboto bold",
	},
	mainTitle: {
		backgroundColor: "#03989E",
		padding: "3px 8px",
		textTransform: "uppercase",
		color: "white",
		borderRadius: "8px",
		letterSpacing: "1px",
		fontSize: "13px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	Subtitle: {
		backgroundColor: "#E0DFDF",
		padding: "2px 8px",
		textTransform: "uppercase",
		color: "#000000",
		borderRadius: "8px",
		marginVertical: "10px",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		paddingTop: "6px",
		fontSize: "7px",
	},
	qrCode: {
		display: "block",
		marginTop: "10px",
		width: "40px",
	},
	qrCodeList: {
		width: "40px",
		height: "40px",
		marginLeft: "15px",
		marginRight: "25px",
	},
	content: {
		fontSize: "9px",
	},
	tableTitle: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		fontFamily: "Roboto bold",
		marginBottom: "5px",
	},
	tableContent: {
		flexDirection: "row",
		justifyContent: "space-between",
		textTransform: "uppercase",
		borderBottom: "2px solid #E0DFDF",
		paddingTop: "8px",
		paddingBottom: "8px",
	},
	containerContentSubHeader: {
		flexDirection: "row",
		justifyContent: "space-between",
	},
	contentQrCodeSubHeader: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		marginLeft: "140px",
	},
	contentRef: {
		marginBottom: "15px",
	},
	dateSale: {
		marginLeft: "60px",
	},
	larguraTexto: {
		textAlign: "center",
		fontSize: "9px",
		width: "12.5%",
		marginBottom: "4px",
	},
	larguraTextoBold: {
		textAlign: "center",
		fontSize: "9px",
		width: "12.5%",
		marginBottom: "4px",
		fontFamily: "Roboto bold",
	},
	containerTotalTalhao: {
		fontSize: "11px",
		paddingTop: "30px",
		alignItems: "flex-end",
		textTransform: "uppercase",
	},
	containerTotalTalhaoGrey: {
		width: "70%",
		flexDirection: "row",
		backgroundColor: "#D9D9D9",
		paddingHorizontal: "15px",
		justifyContent: "space-between",
		padding: "7px",
		borderRadius: "8px",
	},
	containerNumberTotal: {
		width: "80%",
		flexDirection: "row",
		justifyContent: "space-between",
		paddingHorizontal: "30px",
	},
	footer: {
		position: "absolute",
		width: "100vw",
		height: "70px",
		bottom: 0,
		paddingHorizontal: "50px",
	},
	dividerFooter: {
		border: "1px solid #03989E",
	},
	logoFooter: {
		width: "60px",
		height: "60px",
	},
	containerFooter: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paggingContainer: {
		display: "flex",
		flexDirection: "row",
	},
	paggingText: {
		alignItems: "flex-end",
		fontSize: "10px",
		borderRight: "1px solid #03989E",
		paddingHorizontal: "5px",
		color: "#03989E",
	},
	pagnumber: { color: "#03989E", paddingHorizontal: "5px" },
});

const PdfRomaneio = (props) => {
	const date = new Date().toLocaleDateString("pt-BR");
	const hora = new Date().toLocaleTimeString("pt-br", "h:mm A");
	const [company, setCompany] = useState(null);
	const [user, setUser] = useState(null);
	const [property, setProperty] = useState(null);
	const [orderId] = useState(props.pedido);
	const [loading, setLoading] = useState(true);
	const [order, setOrder] = useState(null);

	const businessService = new BusinessService();
	const propertyService = new PropertyService();
	const userService = new UserService();
	const orderService = new OrderService();

	const logo = props.logo;

	useEffect(() => {
		initData();
	}, []);
	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const userLocalData = JSON.parse(localStorage.getItem("user"));

		const companyData = await businessService.getById(userLocalData.businessId);
		setCompany(companyData);

		const userData = await userService.getById(userLocalData.userId);
		setUser(userData);

		const propertyData = await propertyService.getById(propertyId);
		setProperty(propertyData);

		const orderData = await orderService.getOrder(orderId);
		setOrder(orderData);

		setLoading(false);
	};
	//qrcode
	const ids = props.ids;
	function QRCodePageTitle({ id }) {
		const dataUrl = document.getElementById(id).toDataURL();
		return <Image src={dataUrl} style={styles.qrCode} />;
	}

	function QRCodePage(i) {
		const dataUrl = document.getElementById(i.id).toDataURL();
		return <Image src={dataUrl} style={styles.qrCodeList} />;
	}

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	return (
		!loading ?
			<Document>
				<Page size="A4" style={styles.page}>
					<View style={styles.header} fixed>
						<View>
							<Text>Romaneio de</Text>
							<Text style={styles.textTitleDocument}>Vendas</Text>
							<Text style={styles.textBoldFazenda}>
								{company?.nome ?? "Não informado"}
							</Text>
							<Text style={styles.textBoldPropriedade}>
								{property?.nome ?? "Não informado"}
							</Text>
						</View>
						{logo !== null &&
							<Image style={styles.logo} src={logo} />}
					</View>

					<View style={styles.containerSubHeader}>
						<View style={styles.containerContentSubHeader}>
							<View>
								<View style={styles.contentRef}>
									<Text style={styles.textBold}>Número do pedido:</Text>
									<Text style={styles.textNormal}>{order?.numeroPedido}</Text>
								</View>
								<View>
									<Text style={styles.textBold}>Cliente:</Text>
									<Text style={styles.textNormal}>{order?.cliente.nome}</Text>
								</View>
							</View>

							<View style={styles.dateSale}>
								<Text style={styles.textBold}>Data da venda</Text>
								<Text style={styles.textNormal}>
									{order?.data_entrega ? 
										formatDate(order.data_entrega)
										: formatDate(new Date())
									}
								</Text>
							</View>
							<View style={styles.contentQrCodeSubHeader}>
								<Text style={styles.textBold}>qrcode da venda</Text>
								{ids.map((id) => (
									<QRCodePageTitle id={id} key={id} />
								))}
							</View>
						</View>
					</View>
					
					<View style={styles.body}>
						<View style={styles.mainTitle}>
							<Text>Itens da venda</Text>
						</View>

						<View style={styles.content}>
							<View style={styles.Subtitle}>
								<Text style={styles.larguraTextoBold}>qrcode</Text>
								<Text style={styles.larguraTextoBold}>produto</Text>
								<Text
									style={{
										width: 75,
										textAlign: "center",
										fontSize: "9px",
										marginBottom: "4px",
										fontFamily: "Roboto bold",
									}}
								>
									lote
								</Text>
								<Text style={styles.larguraTextoBold}>embalagem</Text>
								<Text style={styles.larguraTextoBold}>volumes</Text>
								<Text style={styles.larguraTextoBold}>quantidade</Text>
								<Text style={styles.larguraTextoBold}>preço unit.</Text>
								<Text style={styles.larguraTextoBold}>preço total</Text>
							</View>

							{order?.itemPedidos.map((orderItem) => (
								orderItem?.lotes.map((harvest, index) => (
									<View key={index} style={styles.tableContent} wrap={false}>
										<QRCodePage id={orderItem.lotes[0].loteId} />
										<Text style={styles.larguraTexto}>
											{harvest.lote.propriedadeCultivo.cultivo.cultura + " " + harvest.lote.propriedadeCultivo.cultivo.variedade}
										</Text>
										<Text
											style={{
												width: 75,
												textAlign: "center",
												fontSize: "9px",
												marginBottom: "4px",
											}}
										>
											{harvest.lote.codigo}
										</Text>
										<Text style={styles.larguraTexto}>
											{orderItem.embalagem.nome + " (" + orderItem.embalagem.quantidade + harvest.lote.unidadeMedida.sigla + ")"}
										</Text>
										<Text style={styles.larguraTexto}>
											{Math.ceil(harvest.quantidade / orderItem.embalagem.quantidade)
												.toString()
												.slice(0, 4)
											}
										</Text>
										<Text style={styles.larguraTexto}>{harvest.quantidade}</Text>
										<Text style={styles.larguraTexto}>{orderItem.preco}</Text>
										<Text style={styles.larguraTexto}>{(orderItem.preco * harvest.quantidade)?.toFixed(2)}</Text>
									</View>
								))
							))}
						</View>
					</View>

					<View style={styles.footer} fixed>
						<View style={styles.dividerFooter}></View>
						<View style={styles.containerFooter}>
							<Image src={LogoHorizontal} style={styles.logoFooter} />
							<View style={styles.paggingContainer}>
								<View style={styles.paggingText}>
									<Text style={{ fontFamily: "Roboto bold" }}>
										https://sisagri.com.br/
									</Text>
									<Text>
										{date +
											" - " +
											hora +
											" - " +
											(user?.nome ?? "Não informado")}
									</Text>
								</View>
								<Text
									style={styles.pagnumber}
									render={({ pageNumber }) => `${pageNumber}`}
								/>
							</View>
						</View>
					</View>
				</Page>
			</Document>
			:<Document></Document>
	);
};

export default PdfRomaneio;
