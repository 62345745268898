import Box from "@mui/material/Box";
import React, { useEffect, useMemo, useState } from "react";
import SearchHeader from "../../components/SearchHeader";
import TopMenu from "../../components/TopMenu";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { visuallyHidden } from "@mui/utils";
import * as I from "../../components/IconsImport";

//import css
import styles from "./Request.module.css";

//import material-ui
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableSortLabel, ThemeProvider, Typography, createTheme, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as locales from "@mui/material/locale";
import PropTypes from "prop-types";
import * as Btn from "../../components/Buttons";

//material ul imports
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { OrderService } from "../../service/order_service";
import Loading from "../../components/Loading";
import { CustomerService } from "../../service/customer_service";
import InputData from "../../components/InputData";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
const columns = [
	{
		id:"clientName",
		label: 	"Cliente",
	},
	{
		id:"orderNumber",
		label:"Nº do pedido",
	},
	{
		id:"invoice",
		label:"Nota fiscal",
	},
	{
		id: "dataRecebimento",
		label: "Data de recebimento",
	},
	{
		id: "products",
		label: "Produtos",
	}
];

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,columns} =
	props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
					
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function Pedidos() {
	const styleBtn = { color: "#00A69A", margin: 0 };
	const [page, setPage] = React.useState(0); //page tabela
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //rows per page
	const [open, setOpen] = useState(false); //modal
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [id, setId] = useState("");
	const [clienteN, setClienteN] = useState("");
	const [openDialog, setOpenDialog] = React.useState(false);
	const [allOrders, setAllOrders] = React.useState([]);
	const [selected, setSelected] = useState([]);
	const [orderBy, setOrderBy] = useState("asc");
	const [orderByColumn, setOrderByColumn] = useState(columns[0].id);
	const [isLoading, setIsLoading] = useState(true);
	const [totalOrderData, setTotalOrderData] = useState(0);
	const [allCustomers, setAllCustomers] = useState([]);
	const [customerId, setCustomerId] = useState(null);
	const [invoice, setInvoice] = useState(null);
	const [harvestId, setHarvestId] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [finalDate, setFinalDate] = useState(null);
	const keys = columns;

	const customerService = new CustomerService();
	const orderService = new OrderService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderByColumn === property && orderBy === "asc";
		setOrderBy(isAsc ? "desc" : "asc");
		setOrderByColumn(property);
	};

	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = allOrders.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	useEffect(() => {
		initData();
	}, [rowsPerPage, page, orderBy, orderByColumn]);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");

		await getAllCustomers(propertyId);

		let orderByValue =  JSON.stringify(
			{
				createdAt: "desc"
			}
		);

		if(orderByColumn === columns[0].id){
			orderByValue =  JSON.stringify(
				{
					cliente: {
						nome: orderBy
					}
				}
			);
		} else if(orderByColumn === columns[1].id){
			orderByValue =  JSON.stringify(
				{
					numeroPedido: orderBy
				}
			);
		}else if(orderByColumn === columns[2].id){
			orderByValue =  JSON.stringify(
				{
					nota_fiscal: orderBy
				}
			);
		}else if(orderByColumn === columns[3].id){
			orderByValue =  JSON.stringify(
				{
					createdAt: orderBy
				}
			);
		} else if(orderByColumn === columns[4].id){
			orderByValue =  JSON.stringify({
				itemPedidos: {
					_count: orderBy
				}
			});
		}
		
		const orderParams = {
			page: page + 1,
			limit: rowsPerPage,
			sortBy: orderByValue
		};

		const orders = await orderService.getByProperty(propertyId, orderParams);

		setTotalOrderData(orders.totalItems);
		setAllOrders(orders.orders.map((order) => {
			let products = "";
			let count = 0;
			order.itemPedidos.forEach((orderItem) => {
				orderItem.lotes.forEach((harvest) => {
					products += `${harvest.lote.propriedadeCultivo.cultivo.cultura} ${harvest.lote.propriedadeCultivo.cultivo.variedade} - ${harvest.quantidade}${harvest.lote.unidadeMedida?.sigla ?? ""}` + (count < (orderItem.lotes.length * order.itemPedidos.length) - 1 ? ", " : "");
				});
				count++;
			});
			return {
				id: order.id,
				clientId: order.clienteId,
				clientName: order.cliente.nome,
				orderNumber: order.numeroPedido ?? "Não informado",
				invoice: order.nota_fiscal ?? "-",
				dataRecebimento: new Date(order.data_entrega).toLocaleDateString("pt-BR"),
				products
			};
		}));
		setIsLoading(false);
	};

	useEffect(() => {
		searchOrders();
	}, [startDate, finalDate, harvestId, invoice, customerId]);

	const searchOrders = async () => {
		const propertyId = localStorage.getItem("propertyId");
		let orderByValue =  JSON.stringify(
			{
				createdAt: "desc"
			}
		);

		if(orderByColumn === columns[0].id){
			orderByValue =  JSON.stringify(
				{
					cliente: {
						nome: orderBy
					}
				}
			);
		} else if(orderByColumn === columns[1].id){
			orderByValue =  JSON.stringify(
				{
					numeroPedido: orderBy
				}
			);
		}else if(orderByColumn === columns[2].id){
			orderByValue =  JSON.stringify(
				{
					nota_fiscal: orderBy
				}
			);
		}else if(orderByColumn === columns[3].id){
			orderByValue =  JSON.stringify(
				{
					createdAt: orderBy
				}
			);
		} else if(orderByColumn === columns[4].id){
			orderByValue =  JSON.stringify({
				itemPedidos: {
					_count: orderBy
				}
			});
		}

		let filterValue = {};

		if(startDate && finalDate){
			filterValue["data_entrega"] = {
				gte: new Date(startDate),
				lte: new Date(finalDate)
			};
		}else if(startDate){
			filterValue["data_entrega"] = {
				gte: new Date(startDate)
			};
		}else if(finalDate){
			filterValue["data_entrega"] = {
				lte: new Date(finalDate)
			};
		}

		if(customerId && Number(customerId) !== -1)
			filterValue["clienteId"] = customerId;

		if(harvestId && harvestId !== ""){
			filterValue["itemPedidos"] = {
				some: {
					lotes: {
						some: {
							lote: {
								codigo: harvestId
							}
						}
					}
				}
			};
		}

		if(invoice)
			filterValue["nota_fiscal"] = invoice;
		
		const orderParams = {
			page: page + 1,
			limit: rowsPerPage,
			sortBy: orderByValue,
			filterValue: encodeURI(JSON.stringify(
				filterValue
			))
		};

		const orders = await orderService.getByProperty(propertyId, orderParams);

		setTotalOrderData(orders.totalItems);
		setAllOrders(orders.orders.map((order) => {
			let products = "";
			let count = 0;
			order.itemPedidos.forEach((orderItem) => {
				orderItem.lotes.forEach((harvest) => {
					products += `${harvest.lote.propriedadeCultivo.cultivo.cultura} ${harvest.lote.propriedadeCultivo.cultivo.variedade} - ${harvest.quantidade}${harvest.lote.unidadeMedida?.sigla ?? ""}` + (count < (orderItem.lotes.length * order.itemPedidos.length) - 1 ? ", " : "");
				});
				count++;
			});
			return {
				id: order.id,
				clientId: order.clienteId,
				clientName: order.cliente.nome,
				orderNumber: order.numeroPedido ?? "Não informado",
				invoice: order.nota_fiscal ?? "-",
				dataRecebimento: new Date(order.data_entrega).toLocaleDateString("pt-BR"),
				products
			};
		}));
		setIsLoading(false);
	};

	const getAllCustomers = async (propertyId) => {
		const customerParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					nome: "asc"
				}
			])
		};
		const customersInProperty = await customerService.getByProperty(customerParams, propertyId);
		setAllCustomers(customersInProperty);
	};

	const openBtn = Boolean(anchorEl);
	const handleClickBtn = (event, row, clienteName) => {
		setId(row);
		setClienteN(clienteName);
		setAnchorEl(event.currentTarget);
	};
	const handleCloseBtn = () => {
		setAnchorEl(null);
	};
	const handleCloseBtndelet = () => {
		setAnchorEl(null);
		handleClickOpen();
	};
	const handleRomaneio = () => {
		return (window.location.href = `/pdfQrcode/?pag=Romaneio&pedido=${id}`);
	};

	const handleClose = () => {
		setOpen(false); //fecha o modal
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //seta a pagina atual tabela
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //seta a quantidade de linhas por pagina
		setPage(0); //seta a pagina atual tabela
	};

	const theme = useTheme();

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales["ptBR"]),
		["ptBR", theme]
	);
	const handleDelete = async () => {
		await orderService.changeVisibility(id);
		initData();
	};
	const handleCloseDialog = () => {
		setOpenDialog(false);
	};
	const handleCloseCancell = () => {
		setAnchorEl(null);
		setOpenDialog(false);
	};
	const handleClickOpen = () => {
		setOpenDialog(true);
	};
	const handleCloseDelet = () => {
		setAnchorEl(null);
		handleDelete();
		setOpenDialog(false);
	};
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "dataRecebimento") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (b[orderBy] < a[orderBy]) 
			return -1;

		if (b[orderBy] > a[orderBy]) 
			return 1;

		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
	
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(allOrders, getComparator(orderBy, orderByColumn)),
		[orderBy, orderByColumn, page, rowsPerPage, allOrders]
	);
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["launch", "requests"]} />
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<SearchHeader
										title="Pedidos"
										href="/pedidos/cadastroPedido"
										textButton="Novo Pedido"
										help="requests"
									>
									</SearchHeader>
								</Item>
							</Grid>
							<Grid item xs={12} sm={12} md={3} className={styles.requestFilter}>
								<Box Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<div
										className={styles.noErrControl}
									>
										<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(customerId ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
												Selecione o cliente
										</Typography>
										<select
											id="client"
											onChange={(e) => setCustomerId(e.currentTarget.value)}
											className={styles.inputRequest}
										>
											<option value={"-1"}>
												{customerId
													? "---------------"
													: "Selecione o cliente"}
											</option>
											{allCustomers.map((item, index) => (
												<option key={index} value={item.id}>
													{item.nome}
												</option>
											))}
										</select>
									</div>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box	Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(invoice ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
										Nota fiscal
									</Typography>
									<input
										className={styles.inputRequest}
										type="text"
										placeholder="Nota fiscal"
										value={invoice}
										onChange={(e) => setInvoice(e.target.value)}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box	Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(harvestId ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
										Lote
									</Typography>
									<input
										className={styles.inputRequest}
										type="text"
										placeholder="Lote"
										value={harvestId}
										onChange={(e) => setHarvestId(e.target.value)}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box	Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<Typography 
										variant="caption" 
										display="block" 
										gutterBottom 
										sx={{
											top:-11,
											position:"absolute",
											px:1,
											backgroundColor:"white",
											visibility:(startDate ? "visible" : "hidden"),
											transition:"all 0.5s ease"
										}} 
									>
										Data inicial
									</Typography>
									<div
										className={styles.noErrControl}
									>
										<InputData placeholder="Data inicial" onDateChange={(date) => setStartDate(date)} classN={styles.inputRequest}/>
									</div>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box	Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<Typography 
										variant="caption" 
										display="block" 
										gutterBottom 
										sx={{
											top:-11,
											position:"absolute",
											px:1,
											backgroundColor:"white",
											visibility:(finalDate ? "visible" : "hidden"),
											transition:"all 0.5s ease"
										}} 
									>
										Data final
									</Typography>
									<div
										className={styles.noErrControl}
									>
										<InputData placeholder="Data final" onDateChange={(date) => setFinalDate(date)} classN={styles.inputRequest}/>
									</div>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
										p: 0,
										m: 0,
									}}
								>
									<Paper sx={{ width: "100%" }}>
										<ThemeProvider theme={themeWithLocale}>
											<Grid item xs={12} sm={3} md={3} sx={{width:"max-content"}}>
												<Btn.GenericButtonGreen
													href="/pdfQrcode/?pag=QrcodePropriedade"
													text="Qr code de Propriedade"
													// icon={
													// 	<div>
													// 		<I.IconAdd />
													// 	</div>
													// }
												/>
											</Grid>
											<TableContainer sx={{ maxHeight: "75vh" }}>
												<Table stickyHeader>
													<EnhancedTableHead
														numSelected={selected.length}
														order={orderBy}
														orderBy={orderByColumn}
														onSelectAllClick={handleSelectAllClicks}
														onRequestSort={handleRequestSort}
														rowCount={allOrders.length}
														columns={columns}
													/>
											
													<TableBody>
														{
															//cria as linhas da tabela
															visibleRows
																.map((row) => {
																	return (
																		<TableRow
																			hover
																			tabIndex={-1}
																			key={row.id}
																			sx={{ height: 3 }}
																			className={styles.lineRequest}
																		>
																			{keys.map((column,index) => {
																				//seta as colunas
																				return (
																					<TableCell
																						id={row.id}
																						// onClick={() => handleOpen(row.id, row.orderNumber)}
																						key={index}
																					>
																						{row[column.id]}
																					</TableCell>
																				);
																			})}

																			<TableCell>
																				<div className={styles.btn}>
																					<Button
																						aria-controls={openBtn ? "basic-menu" : undefined}
																						aria-haspopup="true"
																						aria-expanded={openBtn ? "true" : undefined}
																						id={row.orderNumber}
																						onClick={(e) => handleClickBtn(e, row.id, row.orderNumber)}
																					>
																						<I.IconMoreVert style={styleBtn} />
																					</Button>
																					<Menu
																						id="basic-menu"
																						anchorEl={anchorEl}
																						open={openBtn}
																						onClose={handleCloseBtn}
																						MenuListProps={{
																							"aria-labelledby": "basic-button",
																						}}
																					>
																						{/* <MenuItem onClick={handleCloseBtn}>
																							Editar
																						</MenuItem> */}
																						<MenuItem
																							id={row.id}
																							onClick={() => handleCloseBtndelet()}
																						>
																							Deletar
																						</MenuItem>

																						<MenuItem id={row.id} onClick={handleRomaneio}>
																							Romaneio
																						</MenuItem>
																						<MenuItem id={row.id+"&"+row.orderNumber}  	onClick={() => window.location = `/pedidos/detalhes/${id}` }>
																							Editar
																						</MenuItem>
																						<MenuItem id={row.id+"&"+row.orderNumber}  	onClick={() => window.location = `/etiquetas/${id}` }>
																							Etiquetas
																						</MenuItem>
																					</Menu>
																				</div>
																			</TableCell>
																		</TableRow>
																	);
																})
														}
													</TableBody>
												</Table>
											</TableContainer>
											<TablePagination
												rowsPerPageOptions={[7, 20, 30]}
												component="div"
												colSpan={7}
												count={totalOrderData}
												rowsPerPage={rowsPerPage}
												page={page}
												labelRowsPerPage="Linhas por pagina"
												SelectProps={{
													inputProps: {
														"aria-label": "rows per page",
													},
													native: false,
												}}
												onPageChange={handleChangePage}
												onRowsPerPageChange={handleChangeRowsPerPage}
												ActionsComponent={TablePaginationActions}
											/>
										</ThemeProvider>
									</Paper>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Dialog
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleClose}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Deseja viualiazar mais detalhes do pedido - {clienteN} ?
						</DialogContentText>
					</DialogContent>
					<DialogActions sx={{ display: "flex", flexWrap: "wrap" }}>
						<button className={styles.ButtonCancelarModal} onClick={handleClose}>
							Cancelar
						</button>

						<Link to={`/pedidos/detalhes/${id}`}>
							<button className={styles.ButtonColherModal}>ver</button>
						</Link>
					</DialogActions>
				</Dialog>
				{/* dialog delet */}
				<Dialog
					open={openDialog}
					TransitionComponent={Transition}
					keepMounted
					onClose={handleCloseDialog}
					aria-describedby="alert-dialog-slide-description"
				>
					<DialogContent>
						<DialogContentText id="alert-dialog-slide-description">
							Deseja Deletar - {clienteN} ?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<button
							className={styles.ButtonCancelarModal}
							onClick={handleCloseCancell}
						>
							Cancelar
						</button>
						<button
							className={styles.ButtonColherModal}
							onClick={() => handleCloseDelet()}
						>
							Deletar
						</button>
					</DialogActions>
				</Dialog>
			</div>
		);
}

export default Pedidos;
