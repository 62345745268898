import React, { useEffect, useState } from "react";
import * as Button from "../../components/Buttons";
import SearchHeader from "../../components/SearchHeader";
import HarvestDemand from "../../components/TableHarvest/TableHarvestDemand";
import HarvestRequested from "../../components/TableHarvest/TableHarvestRequested";
import HarvestHarvested from "../../components/TableHarvest/TableHavestHavested";
import TopMenu from "../../components/TopMenu";

//import css
import "../CommonStyle/style.css";
import styles from "./Harvest.module.css";

//tabs table imports
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import * as locales from "@mui/material/locale";

//material ul imports
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import HarvestForecast from "../../components/TableHarvest/TableHarvestForecast";
import { HarvestService } from "../../service/harvest_service";
import Loading from "../../components/Loading";
import { FarmService } from "../../service/farm_service";
import { captalizeFirstLetterEacheWord } from "../../utils/utils";
import InputData from "../../components/InputData";
import { PropertyService } from "../../service/property_service";
import { CropSeasonService } from "../../service/crop_season_service";
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",

	p: 0,
};
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0, width: "100%" }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const subdivisionCategories = {
	PARCELA: "Parcela",
	SETOR: "Setor",
	CANTEIRO: "Canteiro",
	BANCADA: "Bancada",
	BALCAO: "Balcão",
	BLOCO: "Bloco",
	GLEBA: "Gleba",
	QUADRA: "Quadra",
	LOTE: "Lote",
};

function Harvest() {
	const [value, setValue] = React.useState(0);
	
	const [isLoading, setIsLoading] = useState(true);
	const [startDate, setStartDate] = useState(null);
	const [finalDate, setFinalDate] = useState(null);
	const [selectedFarm, setSelectedFarm] = useState(null);
	const [selectedPlot, setSelectedPlot] = useState(null);
	const [selectedSubdivision, setSelectedSubdivision] = useState(null);
	const [selectedCropSeason, setSelectedCropSeason] = useState(null);
	const [allFarms, setAllFarms] = useState([]);
	const [allPlots, setAllPlots] = useState([]);
	const [allSubdivisions, setAllSubdivisions] = useState([]);
	const [allCropSeasons, setAllCropSeasons] = useState([]);

	const harvestService = new HarvestService();
	const farmService = new FarmService();
	const propertyService = new PropertyService();
	const cropSeasonService = new CropSeasonService();

	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		updateCropSeason();
	}, [selectedPlot, selectedSubdivision, selectedFarm]);

	useEffect(() => {
		updatePlotAndSubdivisions();
	}, [selectedCropSeason]);

	const updateCropSeason = async () => {
		let filter = {};

		if(selectedPlot && Number(selectedPlot) !== -1){
			filter = {
				...filter,
				talhaoId: selectedPlot
			};
		}

		if(selectedSubdivision && Number(selectedSubdivision) !== -1){
			filter = {
				...filter,
				subdivisaoId: selectedSubdivision
			};
		}

		if(selectedFarm && Number(selectedFarm) !== -1){
			filter = {
				...filter,
				propriedadeCultivoId: selectedFarm
			};
		}
		const propertyId = localStorage.getItem("propertyId");
		const params = {
			page: 1,
			limit: 100000,
			filterValue: encodeURI(
				JSON.stringify(
					filter
				)
			)
		};

		let cropSeasons = await cropSeasonService.get(propertyId, params);
		cropSeasons = cropSeasons.cropsSeason;
		getCropSeasonsWithDetails(cropSeasons).then((safras) => {
			setAllCropSeasons(safras);
		});
	};

	const updatePlotAndSubdivisions = async () => {
		const cropSeason = allCropSeasons.find((item) => item.id === selectedCropSeason);
		if(cropSeason){
			setPlot(cropSeason?.idTalhao ?? -1);
			setSelectedSubdivision(cropSeason?.idSubdivisao ?? -1);
			setSelectedFarm(cropSeason?.idCultivo ?? -1);
		}
	};

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const farmParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					cultivo: {
						cultura: "asc"
					}
				},
				{
					cultivo: {
						variedade: "asc"
					}
				}
			])
		};

		const farmsInProperty = await farmService.get(farmParams, propertyId);
		setAllFarms(farmsInProperty.farms);

		const plotsInProperty = await propertyService.getPlots(propertyId);
		const plots = plotsInProperty.map((plot) => {
			return {...plot, subdivisoes: getSubdivisions(plot.subdivisoes)};
		});
		setAllPlots(plots);

		let cropSeasons = await cropSeasonService.get(propertyId, {limit: 100000});
		cropSeasons = cropSeasons.cropsSeason;
		getCropSeasonsWithDetails(cropSeasons).then((safras) => {
			setAllCropSeasons(safras);
		});

		setIsLoading(false);
	};

	const getSubdivisions = (subdivisions) => {
		if(!subdivisions || subdivisions.length === 0)
			return [];
		
		let disponibleSubdivisions = [];

		subdivisions.forEach((subdivision) => {
			const disponibleChildren = getSubdivisions(subdivision.subdivisoes);

			disponibleChildren.forEach(child => {
				child = {
					nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento} - ${child.nome}`,
					id: child.id
				};
				disponibleSubdivisions.push(child);
			});
			
			subdivision = {
				nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`,
				id: subdivision.id
			};
			disponibleSubdivisions.push(subdivision);
		});

		return disponibleSubdivisions;
	};

	const getCropSeasonsWithDetails = async (cropSeasons) => {
		const safras = [];
		for (let j = 0; j < cropSeasons.length; j++) {
			const cropSeason = cropSeasons[j];
			const farm = cropSeason.propriedadeCultivo;
			const plot = cropSeason.talhao;

			const cultivation = farm.cultivo;
			const subDiv = cropSeason?.subdivisao;

			const catSub = subDiv?.categoriaSubdivisao;
			safras.push({
				id: cropSeason.id,
				idCultivo: cropSeason.propriedadeCultivoId,
				idTalhao: plot.id,
				idSubdivisao: subDiv?.id,
				safra: cropSeason,
				nome: `Talhão ${plot.nome}${
					subDiv !== null ? " " + captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")}
				-  ${formatDate(cropSeason.dataPlantio)}`,
			});
		}
		return safras;
	};

	function setPlot(e) {
		const plot = allPlots.find((plotToCheck) => plotToCheck.id === e);
		if(plot)
			setAllSubdivisions(plot.subdivisoes);
		else
			setAllSubdivisions([]);
		if(e !== selectedPlot)
			setSelectedSubdivision(-1);
		setSelectedPlot(e);
	}

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const theme = useTheme();

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales["ptBR"]),
		["ptBR", theme]
	);

	const handleDelete = async (id) => {
		await harvestService.changeVisibility(id);
		initData();
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<>
				<TopMenu open={["launch", "harvest"]} />
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 0, sm: 0, md: 0 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<SearchHeader
										title="Colheitas"
										href="/colheita/cadastroColheita"
										textButton="Nova Colheita "
										extraButton={
											<Button.GenericButtonGreen
												href="/colheita/previssaoColheita"
												text={"Previsao de Colheita"}
											/>
										}
										help="harvest"
									>
									</SearchHeader>
								</Item>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<div
										className={styles.noErrControl}
									>
										<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedFarm ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
												Selecione o cultivo
										</Typography>
										<select
											id="farm"
											onChange={(e) => setSelectedFarm(e.currentTarget.value)}
											className={styles.inputRequest}
											value={selectedFarm}
										>
											<option value={"-1"}>
												{selectedFarm && Number(selectedFarm) !== -1
													? "---------------"
													: "Selecione o cultivo"}
											</option>
											{allFarms.map((item, index) => (
												<option key={index} value={item.id}>
													{captalizeFirstLetterEacheWord(item.cultivo.cultura)} {captalizeFirstLetterEacheWord(item.cultivo.variedade ?? "")}
												</option>
											))}
										</select>
									</div>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<div
										className={styles.noErrControl}
									>
										<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedPlot ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
												Selecione o talhão
										</Typography>
										<select
											id="plot"
											onChange={(e) => setPlot(e.currentTarget.value)}
											className={styles.inputRequest}
											value={selectedPlot}
										>
											<option value={"-1"}>
												{selectedPlot && Number(selectedPlot) !== -1
													? "---------------"
													: "Selecione o talhão"}
											</option>
											{allPlots.map((item, index) => (
												<option key={index} value={item.id}>
													Talhão {item.nome}
												</option>
											))}
										</select>
									</div>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<div
										className={styles.noErrControl}
									>
										<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedSubdivision ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
												Selecione a subdivisão
										</Typography>
										<select
											id="subdivision"
											onChange={(e) => setSelectedSubdivision(e.currentTarget.value)}
											className={styles.inputRequest}
											value={selectedSubdivision}
										>
											<option value={"-1"}>
												{selectedSubdivision && Number(selectedSubdivision) !== -1
													? "---------------"
													: "Selecione a subdivisão"}
											</option>
											{allSubdivisions.map((item, index) => (
												<option key={index} value={item.id}>
													{item.nome}
												</option>
											))}
										</select>
									</div>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<div
										className={styles.noErrControl}
									>
										<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedCropSeason ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
												Selecione a safra
										</Typography>
										<select
											id="cropseason"
											onChange={(e) => setSelectedCropSeason(e.currentTarget.value)}
											className={styles.inputRequest}
											value={selectedCropSeason}
										>
											<option value={"-1"}>
												{selectedCropSeason && Number(selectedCropSeason) !== -1
													? "---------------"
													: "Selecione a safra"}
											</option>
											{allCropSeasons.map((item, index) => (
												<option key={index} value={item.id}>
													{item.nome}
												</option>
											))}
										</select>
									</div>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box	Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<Typography 
										variant="caption" 
										display="block" 
										gutterBottom 
										sx={{
											top:-11,
											position:"absolute",
											px:1,
											backgroundColor:"white",
											visibility:(startDate ? "visible" : "hidden"),
											transition:"all 0.5s ease"
										}} 
									>
										Data inicial
									</Typography>
									<div
										className={styles.noErrControl}
									>
										<InputData placeholder="Data inicial" onDateChange={(date) => setStartDate(date)} classN={styles.inputRequest}/>
									</div>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={2} className={styles.requestFilter}>
								<Box	Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr:2,
									position: "relative",
									m:0
								}}>
									<Typography 
										variant="caption" 
										display="block" 
										gutterBottom 
										sx={{
											top:-11,
											position:"absolute",
											px:1,
											backgroundColor:"white",
											visibility:(finalDate ? "visible" : "hidden"),
											transition:"all 0.5s ease"
										}} 
									>
										Data final
									</Typography>
									<div
										className={styles.noErrControl}
									>
										<InputData placeholder="Data final" onDateChange={(date) => setFinalDate(date)} classN={styles.inputRequest}/>
									</div>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "#fff",
										border: "none",
										boxShadow: "none",
										pt: 3,
										p: 1,
										m: 0,
									}}
								>
									<ThemeProvider theme={themeWithLocale}>
										<Grid
											container
											rowSpacing={0}
											columnSpacing={{ xs: 0, sm: 2, md: 3 }}
											sx={{bgColor:"white"}}
										>
											<Grid item xs={12} sm={12} md={6}>
												<Item sx={stylesItem}>
													<Tabs
														value={value}
														onChange={handleChange}
														textColor="white"

														// className="tabs"
													>
														<Tab
															className={
																value === 0
																	? styles.tab + " " + styles.focuz
																	: styles.tab
															}
															label="Demanda"
															{...a11yProps(0)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "25%",
															}}
														/>
														<Tab
															className={
																value === 1
																	? styles.tab + " " + styles.focuz
																	: styles.tab
															}
															label="Realizadas "
															{...a11yProps(1)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "25%",
															}}
														/>
														<Tab
															className={
																value === 2
																	? styles.tab + " " + styles.focuz
																	: styles.tab
															}
															label="Solicitadas "
															{...a11yProps(2)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "25%",
															}}
														/>
														<Tab
															className={
																value === 3
																	? styles.tab + " " + styles.focuz
																	: styles.tab
															}
															label="Previsões"
															{...a11yProps(3)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "25%",
															}}
														/>
													</Tabs>												
												</Item>
												
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<TabPanel
														className={styles.tabPanel}
														value={value}
														index={0}
													>
														<HarvestDemand
															farm={selectedFarm}
															startDate={startDate}
															finalDate={finalDate}
															handleDelete={handleDelete}
														/>
													</TabPanel>
													<TabPanel
														className={styles.tabPanel}
														value={value}
														index={1}
													>
														<HarvestHarvested
															farm={selectedFarm}
															plot={selectedPlot}
															subdivision={selectedSubdivision}
															cropSeason={selectedCropSeason}
															startDate={startDate}
															finalDate={finalDate}
															handleDelete={handleDelete}
														/>
													</TabPanel>
													<TabPanel
														className={styles.tabPanel}
														value={value}
														index={2}
													>
														<HarvestRequested
															farm={selectedFarm}
															plot={selectedPlot}
															subdivision={selectedSubdivision}
															cropSeason={selectedCropSeason}
															startDate={startDate}
															finalDate={finalDate}
															handleDelete={handleDelete}
														/>
													</TabPanel>
													<TabPanel
														className={styles.tabPanel}
														value={value}
														index={3}
													>
														<HarvestForecast
															farm={selectedFarm}
															plot={selectedPlot}
															subdivision={selectedSubdivision}
															cropSeason={selectedCropSeason}
															startDate={startDate}
															finalDate={finalDate}
															handleDelete={handleDelete}
														/>
													</TabPanel>
												</Item>
											</Grid>
										</Grid>
									</ThemeProvider>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</>
		);
}

export default Harvest;
