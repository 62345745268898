import CircleIcon from "@mui/icons-material/Circle";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import HelpButtonInput from "../../../components/HelpButtonInput";
import TopMenu from "../../../components/TopMenu";
import styles from "./SafraCad.module.css";
//components
import RegisterArea from "../../../components/RegistrationArea";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import { Autocomplete, Typography } from "@mui/material";
import { ptBR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputData from "../../../components/InputData";

import { defaults as defaultControls, FullScreen } from "ol/control.js";
import Map from "ol/Map";
import View from "ol/View";
import { XYZ } from "ol/source";
import TileLayer from "ol/layer/Tile";
import { Point, Polygon } from "ol/geom";
import Feature from "ol/Feature";
import {
	Circle as CircleStyle,
	Fill,
	Stroke,
	Icon,
	Style,
	Text,
} from "ol/style";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import parcelaMarkerMap from "../../../Assets/icons/parcelaMarkerMap.png";
import talhaoMarkerMap from "../../../Assets/icons/talhaoMarkerMap.png";
import { captalizeFirstLetterEacheWord, getDateWithoutTimezone } from "../../../utils/utils";

import { FarmService } from "../../../service/farm_service";
import { CropSeasonService } from "../../../service/crop_season_service";
import { PropertyService } from "../../../service/property_service";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

let map = null;
let longitude = null;
let latitude = null;

function CropSeasonCad() {
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const [allDisponiblePlots, setAllDisponiblePlots] = useState([]);
	const [allSubdivisions, setAllSubdivisions] = useState([]);
	const [allFarms, setAllFarms] = useState([]);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [errorMessageData, setErrorMessageData] = useState(false);
	const [errorMessageArea, setErrorMessageArea] = useState(false);
	const [errorMessageariedade, setErrorMessageariedade] = useState(false);
	const [cropSeason, setCropSeason] = useState({
		propriedadeCultivo: null,
		propriedadeCultivoId: null,
		dataPlantio: null,
		talhao: null,
		talhaoId: null,
		subdivisao: null,
		subdivisaoId: null
	});

	const dataRef = useRef(null);
	const areaRef = useRef(null);
	const cultureRef = useRef(null);

	const farmService = new FarmService();
	const cropSeasonService = new CropSeasonService();
	const propertyService = new PropertyService();

	const subdivisionCategories = {
		PARCELA: "Parcela",
		SETOR: "Setor",
		CANTEIRO: "Canteiro",
		BANCADA: "Bancada",
		BALCAO: "Balcão",
		BLOCO: "Bloco",
		GLEBA: "Gleba",
		QUADRA: "Quadra",
		LOTE: "Lote",
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const plots = await cropSeasonService.getPlots(propertyId);
		createMap(plots);
		
		let disponiblePlots = getDisponiblePlots(plots);
		setAllDisponiblePlots(disponiblePlots);

		const farmParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					cultivo: {
						cultura: "asc"
					}
				},
				{
					cultivo: {
						variedade: "asc"
					}
				}
			])
		};
		let farms = await farmService.get(farmParams, propertyId);

		farms = farms.farms.map((farm, index) => {
			return {
				...farm,
				firstLetter: captalizeFirstLetterEacheWord(`${farm.cultivo.cultura} ${farm.cultivo.variedade ?? ""}`)[0],
				title: captalizeFirstLetterEacheWord(`${farm.cultivo.cultura} ${farm.cultivo.variedade ?? ""}`),
				index: index,
			};
		});
		setAllFarms(farms);

		if(id) {
			const cropSeasonToEdit = await cropSeasonService.getById(id);
			const farmToEdit = farms.find((farm) => farm.id === cropSeasonToEdit.propriedadeCultivo.id);
			cropSeasonToEdit.propriedadeCultivo = farmToEdit;
			
			disponiblePlots = getDisponiblePlots(plots, cropSeasonToEdit.subdivisaoId);

			let plotToEdit = disponiblePlots.find((disponiblePlot) => disponiblePlot.id === cropSeasonToEdit.talhao.id);
			if(!plotToEdit){
				plotToEdit = plots.find((plot) => plot.id === cropSeasonToEdit.talhao.id);

				if(plotToEdit.safras.length === 0)
					plotToEdit.disponibleSubdivisions = getDisponibleSubdivisions(plotToEdit.subdivisoes);
				else
					plotToEdit.disponibleSubdivisions = [];
				plotToEdit = {
					...plotToEdit,
					title: captalizeFirstLetterEacheWord(`Talhão ${plotToEdit.nome}`),
					index: disponiblePlots.length,
				};
				disponiblePlots.push(plotToEdit);
				setAllDisponiblePlots(disponiblePlots);
			}
			cropSeasonToEdit.talhao = plotToEdit;

			let subdivision = plotToEdit.disponibleSubdivisions.find((sub) => sub.id === cropSeasonToEdit.subdivisaoId);
			if(!subdivision){
				subdivision = getSubdivision(plotToEdit.subdivisoes, cropSeasonToEdit.subdivisaoId);
				if(subdivision.id)
					setAllSubdivisions([{id: null, nome: "Talhão completo"}].concat(plotToEdit.disponibleSubdivisions).concat([subdivision]));
				else
					setAllSubdivisions([{id: null, nome: "Talhão completo"}].concat(plotToEdit.disponibleSubdivisions));
			}else {
				setAllSubdivisions([{id: null, nome: "Talhão completo"}].concat(plotToEdit.disponibleSubdivisions));
			}
			cropSeasonToEdit.subdivisao = subdivision;

			setCropSeason(cropSeasonToEdit);
		}
	};

	const getDisponiblePlots = (plots, editingSubdivisionId = null) => {
		plots = plots.map((plot, index) => {
			// Se o talhão não tem safras OU tem a subdivisão que estamos editando OU tem uma safra mas é a que estamos editando
			if(plot.safras.length === 0 || 
				plotHasSubdivisionId(plot, editingSubdivisionId) || 
				(id && plot.safras.length === 1 && plot.safras[0].id === id))
				plot.disponibleSubdivisions = getDisponibleSubdivisions(plot.subdivisoes, editingSubdivisionId, plot.id);
			else
				plot.disponibleSubdivisions = [];
			plot = {
				...plot,
				title: captalizeFirstLetterEacheWord(`Talhão ${plot.nome}`),
				index: index,
			};

			return plot;
		});

		plots = plots.filter((plot) => 
			plot.safras.length === 0 && (plot.subdivisoes.length === 0 || 
			plot.disponibleSubdivisions.length > 0) || 
			plotHasSubdivisionId(plot, editingSubdivisionId) ||
			(id && plot.safras.length === 1 && plot.safras[0].id === id)
		);

		return plots;
	};

	const plotHasSubdivisionId = (plot, subdivisionId) => {
		if (!subdivisionId) return false;
		
		const hasSubdivision = (subdivisions) => {
			if (!subdivisions) return false;
			
			for (const sub of subdivisions) {
				if (sub.id === subdivisionId) return true;
				if (hasSubdivision(sub.subdivisoes)) return true;
			}
			return false;
		};

		return hasSubdivision(plot.subdivisoes);
	};

	const getDisponibleSubdivisions = (subdivisions, editingSubdivisionId = null, plotId = null) => {
		if(!subdivisions || subdivisions.length === 0)
			return [];
		
		let disponibleSubdivisions = [];

		subdivisions.forEach((subdivision) => {
			const disponibleChildren = getDisponibleSubdivisions(subdivision.subdivisoes, editingSubdivisionId, plotId);

			// Verifica se a subdivisão atual é a que está sendo editada ou uma ancestral dela
			const isEditingOrParentOfEditing = editingSubdivisionId && (
				subdivision.id === editingSubdivisionId || 
				isParentOfSubdivision(subdivision, editingSubdivisionId)
			);

			// Adiciona os filhos disponíveis se:
			// - A subdivisão está livre OU
			// - A subdivisão é a que está sendo editada OU é ancestral dela
			if(!subdivision.safras || subdivision.safras.length === 0 || isEditingOrParentOfEditing){
				disponibleChildren.forEach(child => {
					child = {
						nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento} - ${child.nome}`,
						id: child.id
					};
					disponibleSubdivisions.push(child);
				});
			}

			// Adiciona a própria subdivisão se ela estiver disponível
			if(getSubdivisionIsDisponible(subdivision, editingSubdivisionId)){
				subdivision = {
					nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`,
					id: subdivision.id
				};
				disponibleSubdivisions.push(subdivision);
			}
		});

		return disponibleSubdivisions;
	};

	// Nova função auxiliar para verificar se uma subdivisão é ancestral de outra
	const isParentOfSubdivision = (subdivision, targetSubdivisionId) => {
		if (!subdivision.subdivisoes) return false;
		
		for (const child of subdivision.subdivisoes) {
			if (child.id === targetSubdivisionId) return true;
			if (isParentOfSubdivision(child, targetSubdivisionId)) return true;
		}
		
		return false;
	};

	const getSubdivisionIsDisponible = (subdivision, editingSubdivisionId = null) => {
		if(subdivision.id === editingSubdivisionId) return true;

		if((!subdivision.safras || subdivision.safras.length === 0) &&
			(!subdivision.subdivisoes || subdivision.subdivisoes.length === 0))
			return true;
			
		if(subdivision.safras && subdivision.safras.length > 0)
			return false;

		let isDisponible = true;
		
		subdivision.subdivisoes.forEach((subdivisionToVerify) => {
			const allChildrenAreDisponible = getSubdivisionIsDisponible(subdivisionToVerify, editingSubdivisionId);
			isDisponible = isDisponible && allChildrenAreDisponible;
		});
		
		return isDisponible;
	};

	const getSubdivision = (subdivisions, subdivisionId) => {
		if(!subdivisionId)
			return {id: null, nome: "Talhão completo"};

		if(!subdivisions || subdivisions.length === 0)
			return {nome: "", id: subdivisionId};

		for(let i = 0; i < subdivisions.length; i++) {
			const subdivision = subdivisions[i];
			if(subdivision.id !== subdivisionId){
				const foundSubdivision = getSubdivision(subdivision.subdivisoes, subdivisionId);

				if(foundSubdivision.nome !== ""){
					return {
						nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento} - ${foundSubdivision.nome}`, 
						id: subdivisionId
					};
				}
			} else {
				return {
					nome: `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`, 
					id: subdivisionId
				};
			}
			
		}

		return {
			nome: "", 
			id: subdivisionId
		};
	};

	const createMap = async (plots) => {
		const propertyId = localStorage.getItem("propertyId");
		const currentProperty = await propertyService.getById(propertyId);

		const coordinates = currentProperty.propriedadeCoordenada;
		if (coordinates  && coordinates.length > 0) {
			const midPoint = getCenterOfPolygon(coordinates);
			longitude = midPoint[0];
			latitude = midPoint[1];

			map = new Map({
				controls: defaultControls().extend([new FullScreen()]),
				target: "map",
				layers: [
					new TileLayer({
						source: new XYZ({
							url: `https://maps.geo.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/maps/v0/maps/${process.env.REACT_APP_MAP_NAME}/tiles/{z}/{x}/{y}?key=${process.env.REACT_APP_MAP_API_KEY}`,
							wrapX: false,
						}),
					}),
				],
				view: new View({
					center: [longitude, latitude],
					zoom: 16,
				}),
			});
		} else {
			navigator.geolocation.getCurrentPosition((position) => {
				latitude = position.coords.latitude;
				longitude = position.coords.longitude;

				map = new Map({
					controls: defaultControls().extend([new FullScreen()]),
					target: "map",
					layers: [
						new TileLayer({
							source: new XYZ({
								url: `https://maps.geo.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/maps/v0/maps/${process.env.REACT_APP_MAP_NAME}/tiles/{z}/{x}/{y}?key=${process.env.REACT_APP_MAP_API_KEY}`,
								wrapX: false,
							}),
						}),
					],
					view: new View({
						center: [longitude, latitude],
						zoom: 16,
					}),
				});
			},
			() => {
				latitude = -3.71839;
				longitude = -38.5434;

				map = new Map({
					controls: defaultControls().extend([new FullScreen()]),
					target: "map",
					layers: [
						new TileLayer({
							source: new XYZ({
								url: `https://maps.geo.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/maps/v0/maps/v0/maps/${process.env.REACT_APP_MAP_NAME}/tiles/{z}/{x}/{y}?key=${process.env.REACT_APP_MAP_API_KEY}`,
								wrapX: false,
							}),
						}),
					],
					view: new View({
						center: [longitude, latitude],
						zoom: 16,
					}),
				});
			}, {
				enableHighAccuracy: true,
			}
			);
		}

		if(plots && plots.length > 0)
			addPlotsToMap(plots);
	};

	const addPlotsToMap = async (plots) => {
		for(let i = 0; i < plots.length; i++){
			let plot = plots[i];
			drawSubdivisions(plot.subdivisoes);
			drawPlot(plot);
		}
	};

	const drawSubdivisions = (subdivisions) => {
		if(subdivisions){
			for(let i = 0; i < subdivisions.length; i++){
				let subdivision = subdivisions[i];
				if(subdivision.subdivisaoCoordenada && subdivision.subdivisaoCoordenada.length > 0)
					subdivision = drawUniqueSubdivisionArea(subdivision);
				drawSubdivisions(subdivision.subdivisoes);
			}
		}
	};

	const drawPlot = (plot) => {
		const midPoint = getCenterOfPolygon(plot.talhaoCoordenada);
		const backgroundColor = plot.safras && plot.safras.length > 0 ? "#ff0000" : "#49A09A";

		let plotName = `Talhão ${plot.nome}`;
		if(plot.safras && plot.safras.length > 0)
			plotName += ` - ${plot.safras[0].propriedadeCultivo.cultivo.cultura} ${plot.safras[0].propriedadeCultivo.cultivo.variedade}`;

		const polygonFeatureT = createPolygonFeatureToMap(
			plot.talhaoCoordenada,
			backgroundColor
		);

		const iconFeature = new Feature({
			geometry: new Point(midPoint),
			name: plotName,
		});

		const iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 550],
				anchorXUnits: "fraction",
				anchorYUnits: "pixels",
				scale: 0.06,
				src: talhaoMarkerMap,
			}),
			text: new Text({
				text: plotName,
				fill: new Fill({
					color: "black",
				}),

				backgroundFill: new Fill({
					color: backgroundColor,
				}),
				textAlign: "center",
				font: "bold 15px sans-serif",
				padding: [5, 5, 5, 5],
				offsetY: -50,
			}),
		});

		iconFeature.setStyle(iconStyle);

		const layer = new VectorLayer({
			source: new VectorSource({
				features: [polygonFeatureT, iconFeature],
			}),
			updateWhileAnimating: true,
			updateWhileInteracting: true,
		});

		map.addLayer(layer);
	};

	const drawUniqueSubdivisionArea = (subdivision) => {
		const isPoint = subdivision.subdivisaoCoordenada.length === 1;
		const midPoint = isPoint ?
			[subdivision.subdivisaoCoordenada[0].longitude, subdivision.subdivisaoCoordenada[0].latitude]
			: getCenterOfPolygon(subdivision.subdivisaoCoordenada);
		
		const backgroundColor = subdivision.safras && subdivision.safras.length > 0 ? "#ff0000" : "#ebef62";

		const featureT = isPoint ?
			createPointFeatureToMap(midPoint, backgroundColor) 
			: createPolygonFeatureToMap(
				subdivision.subdivisaoCoordenada,
				backgroundColor
			);

		let subdivisionName = `${subdivisionCategories[subdivision.categoriaSubdivisao]} ${subdivision.complemento}`;
		if(subdivision.safras && subdivision.safras.length > 0)
			subdivisionName += ` - ${subdivision.safras[0].propriedadeCultivo.cultivo.cultura} ${subdivision.safras[0].propriedadeCultivo.cultivo.variedade}`;

		const iconFeature = new Feature({
			geometry: new Point(midPoint),
			name: subdivisionName,
		});

		const iconStyle = new Style({
			image: new Icon({
				anchor: [0.5, 550],
				anchorXUnits: "fraction",
				anchorYUnits: "pixels",
				scale: 0.06,
				src: parcelaMarkerMap,
			}),
			text: new Text({
				text: subdivisionName,
				fill: new Fill({
					color: "#000",
				}),

				backgroundFill: new Fill({
					color: backgroundColor,
				}),
				textAlign: "center",
				font: "bold 15px sans-serif",
				padding: [5, 5, 5, 5],
				offsetY: -50,
			}),
		});

		iconFeature.setStyle(iconStyle);

		const layer = new VectorLayer({
			source: new VectorSource({
				features: [featureT, iconFeature],
			}),
			updateWhileAnimating: true,
			updateWhileInteracting: true,
		});

		map.addLayer(layer);

		return subdivision;
	};

	const createPointFeatureToMap = (coordinate, color) => {
		const pointFeatureT = new Feature(new Point(coordinate));

		pointFeatureT.setStyle(
			new Style({
				fill: new Fill({
					color: `${color}26`,
				}),
				stroke: new Stroke({
					color: color,
					width: 4,
				}),
				image: new CircleStyle({
					radius: 10,
					fill: new Fill({
						color: color,
					}),
				}),
			})
		);
		return pointFeatureT;
	};

	const createPolygonFeatureToMap = (coordinates, color) => {
		const points = [];
		for (var i = 0; i < coordinates.length; i++)
			points.push([coordinates[i].longitude, coordinates[i].latitude]);

		const polygonFeatureT = new Feature(new Polygon([points]));

		polygonFeatureT.setStyle(
			new Style({
				fill: new Fill({
					color: `${color}26`,
				}),
				stroke: new Stroke({
					color: color,
					width: 4,
				}),
				image: new CircleStyle({
					radius: 10,
					fill: new Fill({
						color: color,
					}),
				}),
			})
		);
		return polygonFeatureT;
	};

	const getCenterOfPolygon = (coordinates) => {
		let lat = 0;
		let lon = 0;
		for (var i = 0; i < coordinates.length; i++) {
			const vertex = coordinates[i];
			lat += Number(vertex.latitude);
			lon += Number(vertex.longitude);
		}
		lat = lat / coordinates.length;
		lon = lon / coordinates.length;
		return [lon, lat];
	};
	
	const validationSave = async () => {
		if (
			cropSeason.dataPlantio &&
			cropSeason.talhao &&
			cropSeason.propriedadeCultivo
		) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			return true;
		} else {
			let message = "Um ou mais campos vazios. - (";
			if (!cropSeason.dataPlantio) {
				message += "Data de início, ";
				// dataRef.current.focus();
				setErrorMessageData(true);
			} else {
				setErrorMessageData(false);
			}
			if (!cropSeason.talhao) {
				message += "Selecionar área, ";
				const input = areaRef.current?.querySelector("input");
				input.focus();
				setErrorMessageArea(true);
			} else {
				setErrorMessageArea(false);
			}
			if (!cropSeason.propriedadeCultivo) {
				message += "Cultura e variedade, ";
				const input = cultureRef.current?.querySelector("input");
				input.focus();
				setErrorMessageariedade(true);
			} else {
				setErrorMessageariedade(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};

	const handleSave = async () => {
		if (!id) {
			const cropSeasonToSave = {
				plantingDate: cropSeason.dataPlantio,
				plotId: cropSeason.talhaoId,
				subdivisionId: cropSeason.subdivisaoId ?? null,
				farmPropertyId: cropSeason.propriedadeCultivoId,
				finished: false
			};

			await cropSeasonService.save(cropSeasonToSave);
		} else {
			const cropSeasonToSave = {
				id: cropSeason.id,
				plantingDate: cropSeason.dataPlantio,
				plotId: cropSeason.talhaoId,
				subdivisionId: cropSeason.subdivisaoId ?? null,
				farmPropertyId: cropSeason.propriedadeCultivoId,
				finished: cropSeason.finalizada
			};

			await cropSeasonService.update(cropSeasonToSave);
		}
	};

	function setArea(plot) {
		if(plot.id !== cropSeason.talhaoId) {	
			setCropSeason({...cropSeason, talhao: plot, talhaoId: plot.id, subdivisaoId: null, subdivisao: {id: null, nome: "Talhão completo"}});
			const subdivisions = [{id: null, nome: "Talhão completo"}].concat(plot.disponibleSubdivisions);
			setAllSubdivisions(subdivisions);
		}
	}

	return (
		<div>
			<TopMenu
				open={["launch", "safra"]}
			/>
			<Box component="main" sx={{ p: 0, mt: 12 }}>
				<Box sx={{ width: "100%", px: 3 }}>
					<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={12} md={12}>
							<Item>
								<RegisterArea
									title={id === null ? "Resgistro de Safra" : "Editar Safra"}
									href="/safra"
									dataCriacao={true}
									dataAlteracao={true}
									salveFunc={validationSave}
									typeMessage={typeMessage}
									message={message}
									help={id === null ? "safraCad" : "safraEdit"}
								>
									{/* inputs da pagina de resgitro */}
									<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
										<Grid item xs={12} sm={12} md={6}>
											<div
												className={
													errorMessageData ? styles.errControl : styles.noErrControl
												}
											><Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(cropSeason.dataPlantio < 10?"hidden":"visible"),transition:"all 0.5s ease"}} >
															Data *
													</Typography>
													<InputData	
														ref={dataRef}													
														placeholder="Data *"
														onDateChange={(e) => setCropSeason({...cropSeason, dataPlantio: new Date(e)})}
														value={cropSeason.dataPlantio ?
															getDateWithoutTimezone(cropSeason.dataPlantio).toLocaleDateString("pt-BR")
															: null
														}
														classN={styles.inputSafra}
													/>
													<p className={errorMessageData ? styles.errText : styles.noErrText}>
													*
													</p>
												</Box>
											</div>
										</Grid>
										<Grid item xs={12} sm={12} md={6} sx={{display:"flex"}}>
											<div
												className={
													errorMessageArea ? styles.errControl : styles.noErrControl
												}
											>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(cropSeason.talhao ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
															Selecionar área *
													</Typography>
													<ThemeProvider theme={theme}>
														<Autocomplete
															ref={areaRef}
															value={cropSeason.talhao}
															onChange={(event, newValue) => {
																setArea(newValue);
															}}
															getOptionLabel={(option) => option.title}
															options={allDisponiblePlots}
															sx={{ width: "100%", border: "none" }}
															renderInput={(params) => (
																<div ref={params.InputProps.ref}>
																	<input
																		type="text"
																		{...params.inputProps}
																		placeholder="Selecionar área *"
																		className={styles.inputSafra}
																	/>
																</div>
															)}
														/>
													</ThemeProvider>
													<p
														className={errorMessageArea ? styles.errText : styles.noErrText}
													>
														*
													</p>
												</Box>
											</div>
											<HelpButtonInput
												title={"Selecionar área"}
												text={
													"Selecione o talhão ou setor onde o cultivo será implantado."
												}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={6}>
											<div
												className={
													errorMessageariedade ? styles.errControl : styles.noErrControl
												}
											>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(cropSeason.propriedadeCultivo ? "visible" : "hidden"),transition:"all 0.5s ease"}} >
															Selecionar Cultura/Variedade *
													</Typography>
													<ThemeProvider theme={theme}>
														<Autocomplete
															ref={cultureRef}
															options={allFarms.sort(
																(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
															)}
															groupBy={(option) => option.firstLetter}
															getOptionLabel={(option) => option.title}
															sx={{ width: "100%", border: "none" }}
															value={cropSeason.propriedadeCultivo}
															onChange={(event, newValue) => {
																setCropSeason({...cropSeason, propriedadeCultivoId: newValue.id, propriedadeCultivo: newValue});
															}}
															renderInput={(params) => (
																<div ref={params.InputProps.ref}>
																	<input														
																		type="text"
																		{...params.inputProps}
																		placeholder="Selecionar Cultura/Variedade *"
																		className={styles.inputSafra}
																	/>
																</div>
															)}
														/>
													</ThemeProvider>
													<p
														className={
															errorMessageariedade ? styles.errText : styles.noErrText
														}
													>
													*
													</p>
												</Box>
											</div>
										</Grid>
										<Grid item xs={12} sm={12} md={6}  sx={{display:"flex"}}>
											<div className={styles.noErrControl}>
												<	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(cropSeason.subdivisao? "visible" : "hidden"),transition:"all 0.5s ease"}} >
															Selecionar subdivisão *
													</Typography>

													<ThemeProvider theme={theme}>
														<Autocomplete
															value={cropSeason.subdivisao}
															onChange={(event, newValue) => {
																setCropSeason({...cropSeason, subdivisao: newValue, subdivisaoId: newValue.id});
															}}
															options={allSubdivisions}
															getOptionLabel={(option) => option.nome}
															sx={{
																width: "100%",
																border: "none",
															}}
															renderInput={(params) => (
																<div ref={params.InputProps.ref}>
																	<input
																		type="text"
																		{...params.inputProps}
																		placeholder="Selecionar subdivisão *"
																		className={styles.inputSafra}
																	/>
																</div>
															)}
														/>
													</ThemeProvider>
												</Box>
											</div>
											<HelpButtonInput
												title={"Selecionar subdivisão"}
												text={
													"Selecione a parcela ou canteiro onde seu cultivo será implementado."
												}
											/>
										</Grid>
										<Grid item xs={12} sm={12} md={12}>
											<Item sx={{ position: "relativo" }}>
												<div id="map" className={styles.map}>
													<div id="marker" title="Marker"></div>
													<div className={styles.mapBtn}>
														<h3 className={styles.red}><CircleIcon style={{ color: "#ff0000",marginRight: 3 }} /> Indisponível</h3>
														<h3 className={styles.yellow}><CircleIcon style={{ color: " #ffff00",marginRight: 3  }} /> Subdivisão disponível</h3>
														<h3 className={styles.green}><CircleIcon style={{ color: " #00a69a",marginRight: 3  }} />Talhão disponível</h3>
													</div>
												</div>
											</Item>
										</Grid>
									</Grid>
								</RegisterArea>
							</Item>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</div>
	);
}
export default CropSeasonCad;
