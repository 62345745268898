import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import InputSearch from "../../../components/SearchHeader/inputSeach";
import TopMenu from "../../../components/TopMenu";
//import components
import Search from "../../../components/SearchHeader";
import Table from "../../../components/TableGenerator";

//material ul imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { Link, useParams } from "react-router-dom";
import styles from "./HarvestCropSeason.module.css";
import Loading from "../../../components/Loading";
import { HarvestService } from "../../../service/harvest_service";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

//column
const columns = [
	{
		id: "farm",
		label: "Cultivo",
	},
	{
		id: "area",
		label: "Área",
	},
	{
		id: "date",
		label: "Data de plantio",
	},
	{
		id: "quantity",
		label: "Quantidade",
	},
]; 

function HarvestCropSeason() {
	const [search, setSearch] = useState("");	
	const [cropSeasons, setCropSeasons] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { id } = useParams();

	const harvestService = new HarvestService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const harvest = await harvestService.getById(id);

		let farm = harvest.propriedadeCultivo.cultivo.cultura;
		if(harvest.propriedadeCultivo.cultivo.variedade)
			farm += ` ${harvest.propriedadeCultivo.cultivo.variedade}`;

		const cropSeasonsToShow = harvest.loteSafras.map((harvestCropSeason) => {

			let area = `Talhão ${harvestCropSeason.safra.talhao.nome}`;
			if(harvestCropSeason.safra.subdivisao)
				area += ` ${captalizeFirstLetterEacheWord(harvestCropSeason.safra.subdivisao.categoriaSubdivisao)} ${harvestCropSeason.safra.subdivisao.complemento}`;

			return {
				id: harvestCropSeason.id,
				farm: farm,
				area: area,
				quantity: harvestCropSeason.quantidade,
				date: formatDate(harvestCropSeason.safra.dataPlantio),
			};
		});
		setCropSeasons(cropSeasonsToShow);
		setIsLoading(false);
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	const dataFilter = cropSeasons.filter((item) => {
		return JSON.stringify(item).toLowerCase().includes(search.toLowerCase());
	});

	function handleClick(e) {
		setSearch(e.target.value);
	}

	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["launch", "harvest"]}/>
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={1}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
										display: "flex"
									}}
								>
									<Search
										// title="Embalagens"
										headerComponents={
											<Link to={"/colheita"} className={styles.headerRegisterAreaButton}>
												<ArrowBackIcon sx={{ mr: 2 }} />
												Safras
											</Link>}
									>
										<InputSearch func={handleClick} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Table
										listSize={dataFilter.length}
										data={dataFilter}
										columns={columns}
										menuTable="disable"
									/>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</div>
		);
}

export default HarvestCropSeason;
